import wx from "weixin-js-sdk"
import api from "@/api/api"

const share=function (url,title,desc,link,imgUrl) {
    console.log(url)
    api.details.geturl(url).then(res => {
        console.log(url)
        if(res.data.code == 0){
            var data=res.data.data
            wx.config({
                    debug: false, 
                    // 开启调试模式,调用的所有api的返回值会在客户端alert出来，
                    //若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。

                    appId: data.appId, // 必填，公众号的唯一标识   在公众号后台找    也可以后端返回

                    timestamp:  data.timestamp, // 必填，生成签名的时间戳  后端返回  示例 : 1566466330

                    nonceStr:  data.nonceStr, // 必填，生成签名的随机串   后端返回  示例 :  'qcmjlufmkiu6ls3pm9fl6zpw4upaphpu'  

                    signature:  data.signature,// 必填，签名   后端返回   示例 : 898c11a276d3ac322ac8fa2a1c9c4478d911bd3f

                    jsApiList: [
                            'updateAppMessageShareData', //分享给好友
                            
                            'updateTimelineShareData', //分享到朋友圈
                            'showMenuItems',
        
                            'hideMenuItems'
                    ] ,// 必填，需要使用的JS接口列表

            });
            wx.ready(()=>{
                 //分享给朋友               
                wx.updateAppMessageShareData({
                    title:title, // 分享标题

                    desc: desc, // 分享描述

                    link: link, // 分享链接

                    imgUrl: imgUrl ,// 分享图标
                    success:function(){
                        // api.details.share(openid,id,from_uid,that.cate_id).then(res=>{
                        //     if(res.data.code == 0){
                        //         that.shareSuccess = true
                        //         that.rec_lists = res.data.rec_lists
                        //     }
                        // })
                    },

                });
                // 分享朋友圈
                wx.updateTimelineShareData({

                    title: title, // 分享标题

                    desc: desc, //分享描述

                    link: link, // 分享链接

                    imgUrl:imgUrl, // 分享图标
                    success:function(){
                        // api.details.share(openid,id,from_uid,that.cate_id).then(res=>{
                        //     if(res.data.code == 0){
                        //         that.shareSuccess = true
                        //         that.rec_lists = res.data.rec_lists
                        //     }
                        // })
                    },
                });




            })
            wx.error(function(res){
                console.log(res)
            })
        }
    })
  }
  export default share