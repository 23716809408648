<template>
  <div class="details" id="new_message">
      <h3>{{data.name}}</h3>
      <div class="iconWrap ">
        <span class="imgwrap">
            <img :src="shareImg" alt=""><span>{{data.num_forward}}</span>
        </span>
        <span class="imgwrap">
            <img :src="seeImg" alt=""><span>{{data.num_read}}</span>
            
        </span>
        <span class="time fr">{{data.create_at}}</span>
      </div>
      <div class="mycard">
           <div class="imgWrap">
               <van-image
                width='1rem'
                height='1rem'
                fit="cover"
                :src="userinfo.user_avatar ? userinfo.user_avatar : defaultImg"
                />    
           </div>
           <div class="info">
               <div class="myinfo">
                  <p class="name">
                      {{userinfo.nick_name}}为您推荐阅读
                  </p>
                  <!--<p class="autograph" v-if="text">-->
                      <!--{{text}}<img :src="modifyImg" alt="" @click="addinfo" v-if="addinfoShow">-->
                  <!--</p>-->
               </div>
           </div>
           <!--<div class="addinfo" v-if="!text" @click="addinfo">-->
               <!--<img :src="add" alt="">添加您的自我介绍-->
           <!--</div>-->
      </div>
      <div class="article" v-html="data.content"></div>
      <div class="btns">
          <div class="l" >
              免责说明
          </div>
          <div class="r">
              展开 <img :src="down" alt="" @click="openMianze">
          </div>
      </div>
      <div class="mianze" v-if="ismianze">
          {{data.remark}}
          <!-- 如涉及作品内容、版权和其他问题，请在30日内与我们联系，我们将在第一时间删除内容！本站文章版权归原作者所有，本站拥有对此声明的最终解释权 -->
      </div>
      <div class="line"></div>
      <div class="bottom"></div>
      <div class="shareWrap">
          <img :src="left" alt="" class="left" @click="backgo">
          <div class="shareBtn" @click="share">
             推广获客
          </div>
      </div>
        <!-- 修改信息弹框 -->
        <div class="popup" v-if="popshow">
            <div class="bg"></div>
            <div class="wrap">
                <div class="upper">
                    <textarea name="content" id="" cols="30" @input='getnum' rows="10" maxlength="100" placeholder="请输入您的自我介绍（100字以内）" v-model="text"></textarea>
                    <span>{{num}}</span>
                </div>
                <div class="btns">
                    <div class="cancle" @click="cancle">
                        取消
                    </div>
                    <div class="determine" @click="determine">
                        保存
                    </div>
                </div>
            </div>
        </div>
        <!-- 分享弹框 -->
        <div class="sharePop" v-if="sharePopshow" @click="sharePopshow=false">
            <div class="bg"></div>
            <div class="content">
                <img :src="sharePopImg" alt="">
                <p class="title">
                    点击“...”推广获客
                </p>
            </div>
        </div>
        <!-- 分享成功 -->
       <van-popup v-model="shareSuccess" round position="bottom" :style="{ height: '80%' }" class="shareSuccess">
           <div class="wrap">
               <div class="topimg">
                   <img :src="shareSuccessImg" alt="">
                   <p class="name">转发成功</p>
                   <p class="tip">
                       以下文章您可能感兴趣
                   </p>
               </div>
               <div class="list">
                   <ul>
                        <li v-for="(v,i) in rec_lists" :key="i">
                            <div class="card clearfix" @click="toDetails(v.id)">
                                <div class="fl titleBox">
                                    <div class="name hidden2">
                                    {{v.name}}
                                    </div>
                                    <div class="iconBox">
                                        <span class="imgwrap">
                                            <img :src="shareImg" alt=""><span>{{v.num_forward}}</span>
                                        </span>
                                        <span class="imgwrap">
                                            <img :src="seeImg" alt=""><span>{{v.num_read}}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="titleMap fr">
                                    <van-image :src="v.cover" alt="" fit='cover' />
                                </div>
                            </div>
                        </li>
                   </ul>
               </div>
               <div class="btn">
                   <router-link to="/">查看更多文章</router-link>
               </div>
           </div>
       </van-popup>
  </div>
</template>

<script>
import api from "@/api/api"
import share from "@/config/share"
export default {
    data(){
        return{
            defaultImg: require("../../img/my/avatar.png"),
            shareImg:require("@/img/icon_zhuanfa.png"),seeImg:require("@/img/icon_yuedu.png"),add:require("@/img/details/icon_tianjia.png"),
            sharePopImg:require("@/img/details/icon_fenxiang.png"),sharePopshow:false,shareSuccess:false,shareSuccessImg:require("@/img/details/icon_zhuanfachengong.png"),
            addinfoShow:true,modifyImg:require("@/img/details/icon_edit.png"),down:require("@/img/details/icon_down.png"),left:require("@/img/details/icon_left.png"),
            ismianze:false,popshow:false,num:100,text:'',from_uid:"",trans_uid:"",title:"",
            data:"",userinfo:"",cate_id:0,
            rec_lists:[],
            openid:"",
        }
    },
    methods:{
        // 免责声明展开
        openMianze(){
            this.ismianze = !this.ismianze
            this.scrollToBottom()
        },
        addinfo(){
            this.popshow = true
        },
        getnum(){
            this.num = 100-this.text.length
        },
        cancle(){
            this.popshow = false
        },
        determine(){
            this.popshow = false
            api.details.modifyIntroduce(this.openid,this.text+"").then(res => {
                if(res.data.code == 0){
                    console.log(res)
                    this.userinfo.introduce = this.text
                    this.$store.commit('keepWxUserInfo',this.userinfo)
                    this.$toast("编辑成功")
                }
            })
        },
        toDetails(id){
            this.sharePopshow = false,this.shareSuccess=false,this.ismianze=false,this.oplist=true,this.popshow=false,this.num=100,this.text=''
            this.getdata(id,"")
        },
        
        share(){
            this.sharePopshow = true
        },
        backgo(){
            var appHttp=window.location.href;
            var appHttpArr=appHttp.split('/');
            console.log(appHttpArr)
            location.href=appHttpArr[0]+'//'+appHttpArr[2]+'/#/'
        },
        //获取数据
        getdata(id,fid){
            api.details.getdata(this.openid,id,fid).then(res => {
                if(res.data.code == 0){
                    console.log(res,1)
                    var articleData = res.data.articleData
                    this.data = articleData
                    this.userinfo = res.data.introduct
                    this.addinfoShow = res.data.introduct.edit
                    this.text=res.data.introduct.introduce
                    this.from_uid=res.data.from_uid
                    this.trans_uid=res.data.trans_uid
                    this.title=articleData.name
                    this.cate_id = articleData.cate_id
                }
            })
        },
        // 滚动到最底部
        scrollToBottom: function () {
            this.$nextTick(() => {
                var container = this.$el.scrollHeight
                 window.scroll({ top: container , left: 0, behavior: 'smooth' });
            });
        }
    },
     created(){
       setTimeout(() => {
        var url=location.href,that=this,id=that.$route.query.id,fid=that.$route.query.from_uid,tid=that.$route.query.trans_uid;
        this.openid = this.$store.state.openid
        this.userinfo= this.$store.state.WxUserInfo
        var encodeUrl=encodeURIComponent(url.split("#/")[0]);
        if(!fid){
            fid=""
        }
        if(that.$route.query.is_show==1){
            api.details.getdata(this.openid,id,fid,tid,1).then(res => {
                if(res.data.code == 0){
                    var articleData = res.data.articleData
                    this.data = articleData
                    this.userinfo = res.data.introduct
                    this.addinfoShow = res.data.introduct.edit
                    this.text=res.data.introduct.introduce
                    this.from_uid=res.data.from_uid
                    this.trans_uid=res.data.trans_uid
                    this.title=articleData.name
                    this.cate_id = articleData.cate_id
                    var link
                    if(!fid){
                        link=url+ "&from_uid=" + this.from_uid+"&trans_uid=" + this.trans_uid
                    }else{
                        let str = url.split("=")
                        str[str.length-2] = this.from_uid+"&trans_uid"
                        str[str.length-1] = this.trans_uid
                        link=str.join("=")
                    }
                    console.log(link)
                    share(encodeUrl,this.title,"",link,articleData.cover,that.openid,id,that.from_uid,that)
                }
            })
        }else {
            api.details.getdata(this.openid,id,fid,tid,0).then(res => {
                if(res.data.code == 0){
                    var articleData = res.data.articleData
                    this.data = articleData
                    this.userinfo = res.data.introduct
                    this.addinfoShow = res.data.introduct.edit
                    this.text=res.data.introduct.introduce
                    this.from_uid=res.data.from_uid
                    this.trans_uid=res.data.trans_uid
                    this.title=articleData.name
                    this.cate_id = articleData.cate_id
                    var link
                    if(!fid){
                        link=url+ "&from_uid=" + this.from_uid+"&trans_uid=" + this.trans_uid
                    }else{
                        let str = url.split("=")
                        str[str.length-2] = this.from_uid+"&trans_uid"
                        str[str.length-1] = this.trans_uid
                        link=str.join("=")
                    }
                    console.log(link)
                    share(encodeUrl,this.title,"",link,articleData.cover,that.openid,id,that.from_uid,that)
                }
            })
        }

       }, 1000);
    },
}
</script>

<style lang="less">
    .details{
        width: 100%;
        h3{
            padding:.32rem .3rem;
            font-size: .36rem;
            font-weight: bold;
        }
        .iconWrap{
            padding-left:.05rem;
            margin-bottom:.12rem;
            .imgwrap{
              display: inline-block;
              font-size:.24rem;
              padding: .06rem .18rem;
              margin-left:  .1rem;
              color:#634FC6;
              img{
                width: .24rem;
                vertical-align: text-top;
                margin-right: .06rem;
              }
              
            }
            .time{
                  font-size:.2rem;
                  color:rgba(191,191,191,1);
                  line-height: .46rem;
                  margin-right: .2rem;
              }
        }
        .mycard{
            width: 6.62rem;
            height: 1.48rem;
            background-color:rgba(255,189,168,0.28);
            border-radius:.16rem;
            margin:0 auto;
            padding:.22rem .36rem;
            box-sizing: border-box;
            position: relative;
            margin-bottom: .32rem;
            .imgWrap{
                position: absolute;
                width:1rem ;
                img{
                    border-radius:50%;
                }
            }
            .info{
                position: absolute;
                left: 1.84rem;
                width: 4rem;
                .myinfo{
                    .name{
                        font-size:.26rem;
                        color:#634FC6;
                        line-height:.53rem;
                    }
                    .autograph{
                        font-size:.24rem;
                        color:#AD9CFF;
                        img{
                            width: .3rem;
                            vertical-align: middle;
                        }
                    }
                }
            }
            .addinfo{
                position: absolute;
                left: 1.6rem;
                top: .9rem;
                width: 2.56rem;
                height:.6rem;
                border-radius:1.24rem;
                background-color:#FF865C;
                font-size:.24rem;
                line-height:.6rem;
                color:#fff;
                text-align: center;
                img{
                    width: 0.26rem;
                    vertical-align: text-top;
                    margin-right: 0.05rem;
                }
            }
        }
        .article{
            width: 6.86rem;
            overflow: hidden;
            margin: 0 auto;
            font-size:.24rem;
            color:#000;
        }
        .btns{
            display: flex;
            text-align: center;
            line-height: 1.08rem;
            font-size:.24rem;
            .l{
                width: 50%;
            }
            .r{
                width: 50%;
                img{
                    width: .4rem;
                    vertical-align: middle;
                }
            }
        }
        .mianze{
            box-sizing: border-box;
            border-top:.02rem solid #E5E3E3;
            width: 6.86rem;
            padding:.16rem .32rem;
            color:#BAB9B9;
            font-size:.24rem;
            margin: 0 auto;
        }
        .line{
            width: 100%;
            height: .02rem;
            background-color:#E5E3E3;
        }
        .shareWrap{
            position: fixed;
            bottom:0;
            left:0;
            width: 100%;
            height: 1.6rem;
            line-height: 1.6rem;
            background-color:#fff;
            .left{
                position: absolute;
                width: .4rem;
                left: 1.1rem;
                top: .62rem;
            }
            .shareBtn{
                position: absolute;
                left: 0;
                right: 0;
                top: .44rem;
                width: 2.08rem;
                height: .72rem;
                line-height: .72rem;
                text-align: center;
                color:#fff;
                background-color:#E45252;
                border-radius:2.72rem;
                padding-left:.35rem;
                box-sizing: border-box;
                margin: 0 auto;
                font-size:.26rem;
                background-image: url('../../img/details/icon_tuiguanghuoke.png');
                background-size: .4rem;
                background-repeat: no-repeat;
                background-position: .3rem .13rem;
            }
        }
        .popup{
            width: 100%;
            height:100%;
            position: fixed;
            left: 0;
            top: 0;
            .bg{
                width: 100%;
                height:100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color:rgba(0,0,0,.3);
            }
            .wrap{
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                margin:-2rem auto;
                width: 6.7rem;
                height: 4.08rem;
                background-color:#fff;
                border-radius:.2rem;
                .upper{
                    width: 100%;
                    height: 3.2rem;
                    padding:.42rem .44rem;
                    box-sizing: border-box;
                    textarea{
                        width: 100%;
                        border:.01rem solid rgba(218,218,218,0.3);
                        height: 2.3rem;
                        font-size:.28rem;
                        padding: .24rem;
                        box-sizing: border-box;
                    }
                    span{
                        position: absolute;
                        right: .6rem;
                        bottom: 1.44rem;
                        color:#BAB9B9;
                        font-size:.24rem;
                    }
                }
                .btns{
                    width: 100%;
                    height: .86rem;
                    border-top:.01rem solid rgba(218,218,218,0.3);
                    line-height: .86rem;
                    text-align: center;
                    font-size:.28rem;
                    .cancle{
                        width: 50%;
                        border-right:.01rem solid rgba(218,218,218,0.3);
                        color:#9B9B9B;
                    }
                    .determine{
                        width: 50%;
                        color:#FF865C;
                    }
                }
            }
        }
        .sharePop{
            width: 100%;
            height:100%;
            position: fixed;
            left: 0;
            top: 0;
            .bg{
                width: 100%;
                height:100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color:rgba(0,0,0,.3);
            }
            .content{
                position: absolute;
                right: 10%;
                top: 5%;
                color:#fff;
                font-size:.5rem;
                img{
                    width: 1rem;
                    position: absolute;
                    right: 0;
                }
                .title{
                    position: absolute;
                    right: 0;
                    top: 1rem;
                    width: 4rem;
                }
            }
        }
        .shareSuccess{
            padding:.6rem .38rem;
            box-sizing: border-box;
            .wrap{
                .topimg{
                    width: 100%;
                    text-align: center;
                    img{
                        width: 1.24rem;
                        height: 1.24rem;
                        border-radius:50%;
                    }
                    .name{
                        font-size:.36rem;
                        color:#000;
                        margin:.3rem 0 .12rem;
                    }
                    .tip{
                        font-size:.24rem;
                        color:#B1B1B1;
                    }
                }
                .list{
                    width: 100%;
                    height: 5rem;
                    ul{
                        height: 5rem;
                        overflow-y: scroll;
                        li{
                            .card{
                                width: 6.56rem;
                                padding-bottom:.4rem;
                                margin: .24rem auto;
                                border-bottom:.01rem solid #F4F4F4;
                                .titleBox{
                                     width: 4.42rem;
                                .name{
                                    font-size:.3rem;
                                    height: .9rem;
                                    font-weight: bold;
                                    color: rgba(26,28,36,1);
                                }
                                .vis{
                                    visibility: hidden;
                                }
                                .iconBox{
                                    .imgwrap{
                                    display: inline-block;
                                    font-size:.24rem;
                                    padding: .06rem .18rem;
                                    margin-left:  .1rem;
                                    color:#634FC6;
                                    img{
                                        width: .26rem;
                                        vertical-align: text-top;
                                        margin-right: .06rem;
                                    }
                                    }
                                    .label{
                                    font-size:.24rem;
                                    padding: .06rem .18rem;
                                    margin-right:  .24rem;
                                    border-radius:.04rem;
                                    float: right;
                                    }
                                    .label1{
                                    color:#FF865C;
                                    background-color:#FFF1DE;
                                    }
                                    .label2{
                                    color:#3ADF72;
                                    background-color:#DAFFE9;
                                    }
                                    .label3{
                                    color:#56B7EF;
                                    background-color:#CCEDFF;
                                    }
                                }
                                }
                                .titleMap{
                                width: 2rem;
                                height:1.44rem;
                                img{
                                    width: 2rem;
                                    height:1.44rem;
                                    border-radius: .08rem;
                                    object-fit: cover;
                                }
                                }
                            }
                        }
                    }
                }
                .btn{
                    width: 100%;
                    height: 2.98rem;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background-color:#fff;
                     a{
                        display: block;
                        width: 5.56rem;
                        height: .84rem;
                        margin: .64rem auto;
                        line-height: .84rem;
                        text-align: center;
                        border-radius:1.42rem;
                        color:#fff;
                        background-color:#E45252;
                    }
                }
            }
        }
        .bottom{
         height: 1.6rem;   
        }
    }
</style>